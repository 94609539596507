<template>
  <div>
    <div class="container order-view">
      <template v-if="Object.keys(cartData).length">
        <!--  收货人信息    -->
        <div class="card card__address">
          <div
            class="title"
            flex="main:left cross:center"
          >收货人信息</div>
          <van-form ref="form">
            <van-field
              v-model="address.name"
              name="姓名"
              label="姓名"
              placeholder="姓名"
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field
              v-model="address.phone"
              name="电话"
              label="电话"
              placeholder="电话"
              :rules="[{ required: true, message: '请填写电话' }]"
            />
            <van-field
              v-model="address.address"
              name="地址"
              label="地址"
              placeholder="地址"
              :rules="[{ required: true, message: '请填写地址' }]"
            />
            <van-field
              v-model="address.remark"
              name="备注"
              label="备注"
              placeholder="备注"
              :rules="[{ required: true, message: '请填写备注' }]"
            />
          </van-form>
        </div>
        <!--   商品   -->
        <m-cart-goods />
        <van-submit-bar
          :price="totalPrice"
          button-text="确认购买"
          @submit="handleOrder"
        />
      </template>
      <!--  购物车为空    -->
      <template v-else>
        <van-empty
          description="购物车暂无数据"
          flex="main:center cross:center"
        >
          <router-link to="/m/index">
            <van-button
              round
              type="primary"
              class="empty-btn"
            >继续购物</van-button>
          </router-link>
        </van-empty>
      </template>
    </div>
    <!--  支付    -->
    <van-overlay
      :show="payShow"
      :custom-style="{zIndex: 999, background: 'rgba(0,0,0,1)'}"
    >
      <div
        class="pay-info"
        flex="dir:top main:center cross:center"
      >
        <img
          :src="pay.qrcode"
          alt=""
        >
        <p>请使用支付宝或微信扫码进行支付</p>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import MCartGoods from '@/components/MCartGoods/index.vue'
import { calcPrice } from '@/utils/cart'
export default {
  name: 'OrderView',
  components: { MCartGoods },
  data() {
    return {
      payShow: false,
      address: {
        name: '',
        phone: '',
        address: ''
      },
      remark: '',
      pay_type: 1,
      pay: { id: '', qrcode: '', orderId: '' }
    }
  },
  computed: {
    cartData() {
      return this.$store.getters.cartData
    },
    totalPrice() {
      return calcPrice() * 100
    }
  },
  watch: {
    cartData: {
      deep: true,
      immediate: true,
      handler() {

      }
    }
  },

  methods: {
    handleOrder() {
      if (!this.address.name || !this.address.phone || !this.address.address) {
        this.$dialog.alert({ message: '请完整填写联系方式：姓名，电话，地址' })
        return true
      }

      const loading = this.$ddLoading()
      this.$api.order.create({ address: this.address, remark: this.remark, source: 13 }).then(res => {
        // this.pay.qrcode = res.data.qrcode
        // this.pay.id = res.data.pay_id
        // this.pay.orderId = res.data.order_id
        // this.payShow = true
        this.$store.dispatch('cart/clear')
        window.location.href = res.data.pay_url + '?redirect=' + encodeURIComponent(window.location.origin + '/m/order/' + res.data.order_id + '/success')
        // this.queryPay()
      }).catch(() => {
        loading.close()
      })
    },
    queryPay() {
      const index = setInterval(() => {
        this.$api.pay.query(this.pay.id).then(res => {
          if (res.data.status) {
            this.$router.push(`/m/order/${this.pay.orderId}/success`)
            clearInterval(index)
          }
        })
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
.order-view {
  padding: 0 0 4rem;

  .card{
    margin-bottom: 1rem;
    .title{
      background: #eee;
      height: 3.2rem;
      padding: 0 1.2rem;
      font-size: 1rem;
    }
  }

  .empty-btn{
    width: 12.4rem;
    height: 3.2rem;
    background: linear-gradient(to right, #F18C0F ,#E8420B);
  }
}
.pay-info{
  height: 80vh;
  img {
    width: 18.4rem;
  }
  p{
    color: #fff;
    padding: 1.6rem 0;
    font-size: 1.2rem;
  }
}
</style>
