<template>
  <footer v-if="showPathHeader">
    <div class="contact">
      <div class="title">联系我们</div>
      <div class="subtitle">周一至周日 9:00-18:00</div>
      <div
        class="item-box"
        flex="main:justify cross:center"
      >
        <a
          class="item"
          flex="dir:top main:center cross:center"
          :href="`tel:${ contact.tel}`"
        >
          <van-icon
            name="phone"
            size="2.4rem"
            color="#545658"
          />
          <span>热线服务</span>
        </a>
        <a
          class="item"
          flex="dir:top main:center cross:center"
          :href="`tencent://message/?uin=${contact.qq}`"
        >
          <van-icon
            name="qq"
            size="2.4rem"
            color="#545658"
          />
          <span>在线客服</span>
        </a>
      </div>
    </div>
    <div class="contact">
      <div class="title">关注我们</div>
      <div
        class="icon-box"
        flex="main:left cross:center"
      >
        <!--        <div-->
        <!--          class="icon-item"-->
        <!--          flex="main:center cross:center"-->
        <!--        >-->
        <!--          <van-icon-->
        <!--            name="miniprogram-o"-->
        <!--            size="2.6rem"-->
        <!--          />-->
        <!--        </div>-->
        <div
          class="icon-item"
          flex="main:center cross:center"
          @click="handleShow('official')"
        >
          <van-icon
            name="wechat"
            size="1.8rem"
          />
        </div>
      </div>
    </div>
    <div class="content-other">
      <div class="item">营业执照：{{ site.cert }}</div>
      <div class="item">
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank"
        >{{ site.beian }}</a>
      </div>
      <div class="item">{{ site.address }}</div>
      <div class="item">
        ©{{ endTime }}贵州多多收银软件技术有限公司
      </div>
    </div>
    <van-dialog
      v-model="showModal"
      :show-confirm-button="false"
      class="qrcode-dialog"
    >
      <div
        v-if=" modalType === 'official'"
        class="modal-content"
        flex="dir:top main:center cross:center"
      >
        <div class="qrcode">
          <van-image
            v-if="modalType"
            width="18rem"
            height="18rem"
            :src="require('@/assets/official-qrcode.jpg')"
          />
        </div>
        <van-icon
          name="close"
          color="#fff"
          size="2.8rem"
          @click="showModal = false"
        />
      </div>
    </van-dialog>
  </footer>
</template>

<script>
import dayjs from 'dayjs'
import setting from '@/setting'
export default {
  name: 'LayoutMFooter',
  data() {
    return {
      site: setting.site,
      contact: setting.contact,
      showModal: false,
      modalType: ''
    }
  },
  computed: {
    endTime() {
      return dayjs().year()
    },
    showPathHeader() {
      return this.$route.path.indexOf('/m/detail') === -1 &&
        this.$route.path.indexOf('/m/cart') === -1 &&
        this.$route.path.indexOf('/m/order') === -1
    }
  },
  methods: {
    handleShow(type) {
      this.modalType = type
      this.showModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
footer{
  .contact{
    padding: 2rem 1rem 0;
    .title{
      color: #3d3e40;
      font-weight: bold;
      font-size: 1.2rem;
      margin-bottom: .2rem;
    }

    .subtitle{
      color: #9da1a6;
      font-size: 1rem;
      margin-bottom: .2rem;
    }

    .item-box{
      margin-top: .8rem;
      .item{
        flex: 1;
        height: 8rem;
        background: #f7f8fa;
        border-radius: .6rem;
        margin-right: 1rem;
        &:last-child{
          margin-right: 0;
        }

        span{
          margin-top: .2rem;
        }
      }
    }

    .icon-box{
      margin-top: .8rem;
      .icon-item{
        margin-right: 1rem;
        background: #D0D2DE;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        color: #fff;
        overflow: hidden;
      }
    }

  }
  .content-other{
    background: #333;
    padding: 1.6rem 1rem 3rem;
    margin-top: 2rem;
    .item{
      color: #9f9fa6;
      margin-bottom: .6rem;
      a{
        color: #9f9fa6;
      }
    }
  }
}

.qrcode-dialog{
  background: transparent;

  .modal-content{
    .qrcode{
      background: #fff;
      padding: 1.2rem;
      margin-bottom: 1.2rem;
      border-radius: .8rem;
    }
  }
}
</style>
