<template>
  <div class="cart-goods">
    <van-card
      v-for="(item, key) in cartData"
      :key="key"
      :price="item.sale_price"
      :title="item.name"
      :desc="item.desc"
      :thumb="item.cover_img+'?x-oss-process=image/resize,w_500,limit_0'"
    >
      <template #footer>
        <van-stepper
          v-model="item.count"
          :max="item.stock_num"
          :min="0"
          input-width="32px"
          button-size="26px"
          @minus="handleChange(item, 'min')"
          @plus="handleChange(item, 'add')"
        />
      </template>
    </van-card>
  </div>
</template>

<script>
import { calcPrice, save } from '@/utils/cart'
export default {
  name: 'MCartGoods',
  data() {
    return {}
  },
  computed: {
    cartData() {
      return this.$store.getters.cartData
    },
    totalPrice() {
      return calcPrice()
    }
  },
  watch: {
    cartData: {
      deep: true,
      immediate: true,
      handler(val) {

      }
    }
  },
  methods: {
    handleChange(product, type) {
      if (type === 'add') {
        product.count += 1
      } else if (type === 'min') {
        product.count -= 1
      }
      const loading = this.$ddLoading()
      this.$store.dispatch('cart/store', save(product)).then(res => {
        loading.close()
      })
    },
    handleDelete(product) {
      product.count = 0
      const loading = this.$ddLoading()
      this.$store.dispatch('cart/store', save(product)).then(res => {
        loading.close()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
.cart-goods {
  .input{
    border: 1px solid #fff;
    background: #eee;
    padding: 6px 12px;
    height: 42px;
    line-height: 42px;
    font-size: 16px;
    transition: .5s;
    width: 100%;
    &:focus{
      border: 1px solid $main-color;
      background: #fefefe;
    }
  }

  .delete{
    color: $main-color;
    cursor: pointer;
  }

  .table{
    width: 100%;
    border: 1px solid #ebebeb;
    border-spacing: 0;
    margin-bottom: 42px;
    thead{
      background: #EBEBEB;
      color: #666;
      height: 42px;
    }

    tbody{
      background: #fff;
    }

    tr>th,
    tr>td{
      padding: 12px 0;
      font-size: 16px;
      text-align: center;
      border-top: 1px solid #eee;
    }

    .goods-info{
      width: 310px;
      padding: 0 16px;

      span{
        text-align: left;
        padding-left: 12px;
      }
    }
  }

  .total{
    background: #EBEBEB;
    height: 56px;
    font-size: 16px;
    padding: 0 32px;
    margin-bottom: 42px;
    span{
      color: $main-color;
      font-weight: 500;
    }
  }
}
</style>
