<template>
  <header
    v-if="showPathHeader"
    :class="{'header-show': showHeader}"
  >
    <div
      class="logo-wrap"
      flex="main:justify cross:center"
    >
      <span />
      <div class="logo" />
      <router-link
        to="/cart"
        class="cart"
      >
        <van-icon
          name="shopping-cart-o"
          size="18px"
        />
      </router-link>
    </div>
    <layout-m-nav />
  </header>
</template>

<script>
import LayoutMNav from '@/components/Layout/modules/Mobile/MNav'
export default {
  name: 'LayoutMHeader',
  components: { LayoutMNav },
  props: {
    scrollTop: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showHeader: true
    }
  },
  computed: {
    showPathHeader() {
      return this.$route.path.indexOf('/m/detail') === -1 &&
        this.$route.path.indexOf('/m/cart') === -1 &&
        this.$route.path.indexOf('/m/order') === -1
    }
  },
  watch: {
    scrollTop(scrollTop, oldValue) {
      if (scrollTop - oldValue >= 80 || scrollTop >= 80) { // 向下滚动
        this.showHeader = false
      } else if (scrollTop < oldValue) { // 向上滚动
        this.showHeader = true
      }
    }
  },
  methods: {
    handleClick() {
      this.$router.push({ path: '/cart' })
    }
  }
}
</script>

<style lang="scss" scoped>
header{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
  transition: transform .3s;
  transform: translateY(-100%);
  &.header-show{
    transform: translateY(0);
  }
  .logo-wrap{
    position: relative;
    padding: .6rem 1rem .6rem;
    .logo{
      background: url("@/assets/logo-big.png") no-repeat center;
      background-size: contain  ;
      height: 2rem;
      flex: 1;
    }
  }
}

</style>
