<template>
  <div
    v-if="Object.keys(detail).length > 0"
    class="container"
  >
    <van-swipe
      class="cover-img"
      :autoplay="5000"
      indicator-color="white"
    >
      <van-swipe-item>
        <van-image
          height="26rem"
          fit="cover"
          :src="detail.cover_img+'?x-oss-process=image/resize,w_500,limit_0'"
        />
      </van-swipe-item>
      <van-swipe-item
        v-for="(image, index) in detail.album"
        :key="index"
      >
        <van-image
          height="26rem"
          fit="cover"
          :src="image+'?x-oss-process=image/resize,w_500,limit_0'"
        />
      </van-swipe-item>
    </van-swipe>
    <section class="prod">
      <div class="prod-info-price-box">
        <div
          class="prod-info-price"
          flex="main:left cross:bottom"
        >
          <span class="symbol">&yen;</span>
          <span class="number">{{ detail.sale_price }}</span>
        </div>

        <div class="prod-info-box">
          <div
            class="cover-desc"
            flex="main:left cross:center"
          >
            <div class="cover-desc__item"><van-icon name="passed" />官方自营</div>
            <div class="cover-desc__item"><van-icon name="passed" />7x12小时客服服务</div>
          </div>
          <div class="cover-contact">
            对于购买 零售行业软件 有任何疑问？ <a :href="`tencent://message/?uin=${contact.qq}`">咨询人工客服 &gt;</a>
          </div>
        </div>
      </div>

      <div class="goods-info">
        <div class="goods-info__name">{{ detail.name }}</div>
        <div class="goods-info__desc">{{ detail.desc }}</div>
      </div>
    </section>

    <div
      class="prod-desc"
      v-html="detail.editor_desc"
    />

    <van-goods-action>
      <van-goods-action-icon
        icon="wap-home-o"
        text="首页"
        @click="$router.push('/m/index')"
      />
      <van-goods-action-icon
        icon="cart-o"
        text="购物车"
        @click="$router.push('/m/cart')"
      />
      <van-goods-action-button
        type="warning"
        text="加入购物车"
        @click="handleAddCart('cart')"
      />
      <van-goods-action-button
        type="danger"
        text="立即购买"
        @click="handleAddCart('buy')"
      />
    </van-goods-action>
  </div>
</template>

<script>
import setting from '@/setting'
import { save } from '@/utils/cart'
export default {
  name: 'DetailView',
  data() {
    return {
      id: 0,
      detail: {},
      contact: setting.contact,
      number: 1
    }
  },
  computed: {},
  mounted() {
    this.id = this.$route.params.id
    this.getDetail()
  },
  methods: {
    handleAddCart(type) {
      const product = JSON.parse(JSON.stringify(this.detail))
      product.count = this.number

      this.$store.dispatch('cart/store', save(product, 'add')).then(res => {
        if (type === 'cart') {
          this.$toast('添加成功')
          // this.$router.push('/m/cart')
        } else {
          this.$router.push('/m/order')
        }
      })
    },
    getDetail() {
      this.$api.mch.goodsDetail(this.id).then(res => {
        this.detail = res.data
        document.title = this.detail.name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
.container{
  padding-bottom: 4.8rem;
  .cover-img{
    height: 26rem;
  }

  .prod{
    position: relative;
    margin:  -2rem .6rem 0;
    .prod-info-price-box{
      .prod-info-price{
        background: linear-gradient(to left, #F18C0F, #E8420B);
        color: #fff;
        border-top-left-radius: .6rem;
        border-top-right-radius: .6rem;
        padding: 1.6rem .5rem;
        font-weight: bold;
        .symbol{
          font-size: .8rem;
          margin-left: .8rem;
        }
        .number{
          margin-left: .1rem;
          font-size: 2.2rem;
          line-height: 1.8rem;
        }
      }

      .prod-info-box{
        background: #fff;
        padding: 1.2rem .8rem;
        border-bottom-left-radius: .6rem;
        border-bottom-right-radius: .6rem;
        .cover-desc{
          &__item{
            font-size: 1rem;
            border: .01389rem solid #ff99a7;
            background: #fff3f2;
            color: #e6001f;
            border-radius: .2rem;
            padding: .1rem .8rem;
            margin-right: .5rem;
            margin-bottom: .6rem;
            .van-icon{
              margin-right: .2rem;
            }
          }
        }

        .cover-contact{
          color: $main-color;
          font-size: .92rem;
          a{
            color: $main-color;
          }
        }
      }
    }

    .goods-info {
      margin-top: .8rem;
      background: #fff;
      padding: 1.2rem .6rem;
      border-radius: .6rem;

      &__name {
        font-size: 1.6rem;
        font-weight: 700;
        padding-bottom: .6rem;
        border-bottom: .05rem solid #f5f5f5;
      }

      &__desc {
        color: #A3A3A3;
        font-size: 1rem;
        margin-top: .6rem;
      }
    }
  }

  .prod-desc{
    width: 100vw;
    overflow-x: hidden;
    margin-top: .8rem;
    background: #fff;
  }

}
</style>
