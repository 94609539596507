import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/PC/Index.vue'
import CateView from '../views/PC/Cate.vue'
import CartView from '../views/PC/Cart.vue'
import DetailView from '../views/PC/Detail.vue'
import OrderView from '../views/PC/Order.vue'
import OrderSuccessView from '../views/PC/OrderSuccess.vue'
import ProductsIntroduceView from '../views/PC/Products/Introduce.vue'

import MIndexView from '../views/Mobile/Index.vue'
import MCateView from '../views/Mobile/Cate.vue'
import MCartView from '../views/Mobile/Cart.vue'
import MDetailView from '../views/Mobile/Detail.vue'
import MOrderView from '../views/Mobile/Order.vue'
import MOrderSuccessView from '../views/Mobile/OrderSuccess.vue'
import MProductsIntroduceView from '../views/Mobile/Products/Introduce.vue'

import setting from '@/setting'
import Layout from '@/components/Layout/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: 'index',
        name: 'Index',
        component: IndexView
      },
      {
        path: 'cate/:id',
        name: 'CateView',
        component: CateView
      },
      {
        path: 'cart',
        name: 'CartView',
        component: CartView,
        meta: {
          title: setting.title + '购物车'
        }
      },
      {
        path: 'detail/:id',
        name: 'DetailView',
        component: DetailView
      },
      {
        path: 'order',
        name: 'OrderView',
        component: OrderView
      },
      {
        path: 'order/:id/success',
        name: 'OrderView',
        component: OrderSuccessView
      },
      {
        path: 'products/introduce',
        name: 'ProductsIntroduce',
        component: ProductsIntroduceView,
        meta: {
          title: '百年年味 ！食用指南'
        }
      }
    ]
  },
  {
    path: '/m',
    component: Layout,
    redirect: '/m/index',
    children: [
      {
        path: 'index',
        name: 'MIndex',
        component: MIndexView
      },
      {
        path: 'cate/:id',
        name: 'MCateView',
        component: MCateView
      },
      {
        path: 'cart',
        name: 'MCartView',
        component: MCartView,
        meta: {
          title: setting.title + '购物车'
        }
      },
      {
        path: 'detail/:id',
        name: 'DetailView',
        component: MDetailView
      },
      {
        path: 'order',
        name: 'OrderView',
        component: MOrderView
      },
      {
        path: 'order/:id/success',
        name: 'OrderView',
        component: MOrderSuccessView
      },
      {
        path: 'products/introduce',
        name: 'ProductsIntroduce',
        component: MProductsIntroduceView,
        meta: {
          title: '百年年味 ！食用指南'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || setting.title
  next()
})

export default router
