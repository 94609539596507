<template>
  <div
    v-if="Object.keys(order).length"
    class="container order-view"
  >

    <div
      class="success"
      flex="main:center cross:center"
    >
      <van-icon
        name="passed"
        color="#71B247"
      />
      下单成功，请保存好订单号！
    </div>

    <div
      class="order-info"
    >
      <div class="order-info__tips">
        <div class="item">订单号:{{ order.id }} <span @click="handleCopy(order.id)">[复制]</span></div>
        <div class="item item__success">购买成功</div>
        <div class="tips">订单已经完成，感谢您使用！</div>
        <div flex="main:center">
          <van-button
            round
            type="primary"
            class="cart-btn"
            @click="$router.push('/m/index')"
          >继续购物</van-button>
        </div>

      </div>
    </div>

    <div class="address-info">
      <div class="title">收货人信息</div>
      <div class="item">收货人姓名：{{ order.address.name }}</div>
      <div class="item">收货人姓名：{{ order.address.tel }}</div>
      <div class="item">收货人地址：{{ order.address.address_region }}{{ order.address.address_detail }}</div>
    </div>

    <div class="order-detail">
      <van-card
        v-for="(item, key) in order.details"
        :key="key"
        :num="item.goods_num"
        :price="item.sale_price"
        :title="item.goods_name"
        :thumb="item.cover_img+'?x-oss-process=image/resize,w_500,limit_0'"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderView',
  data() {
    return {
      id: '',
      order: {}
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getInfo()
  },
  methods: {
    handleCopy(id) {
      navigator.clipboard.writeText(`您的订单号为：${id}, \n订单地址为：http://mall.duoduosodt.cn/order/${id}/success`)
      this.$toast.success('订单号已复制')
    },
    getInfo() {
      this.$api.order.detail(this.id).then(res => {
        this.order = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
$success: #71B247;
.order-view {
  padding: 36px 0 72px;

  .success{
    font-size: 1.8rem;
    color: $success;
    margin-bottom: 1.8rem;
  }

  .order-info{
    background: #ffffff;
    padding: .8rem .8rem;
    border-top: .6rem solid $success;
    position: relative;
    &:after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-position: 0 -117px;
      background-repeat: repeat-x;
      background-image: url("@/assets/order-icons.png");
    }
    &__tips{
      padding-bottom: 3.4rem;

      .item{
        color: #999;
        &__success{
          margin-top: 48px;
          text-align: center;
          color: $success;
          font-size: 36px;
          font-weight: 600;
        }
        span{
          cursor: pointer;
          &:hover{
            color: $main-color;
          }
        }
      }
      .tips{
        color: #999;
        text-align: center;
      }
    }
  }

  .cart-btn{
    border-radius: .4rem !important;
    width: 12.4rem;
    height: 3.2rem;
    background: linear-gradient(to right, #F18C0F ,#E8420B);
    margin-top: 1.2rem;
  }

  .address-info{
    background: #fff;
    padding: .8rem 1rem 1.6rem;
    margin-top: 1.2rem;

    .title{
      font-size: 1.2rem;
      color: #999;
      margin-bottom: 1.2rem;
    }
    .item{
      font-size: 1.2rem;
      color: #333;
      padding: .4rem 0;
    }
  }

  .order-detail{
    margin-top: 1.2rem;
  }
}
</style>
