<template>
  <div class="wrapper">
    <!--  微店广告   -->
    <a
      href="javascript:;"
      class=" dd-trade dd-trade__app"
    />
    <!-- 分类   -->
    <div class="cate-container">
      <div
        class="goods-container"
        flex="dir:left wrap:wrap"
      >
        <template v-for="arr in goodsList">
          <div
            v-for="item in arr"
            :key="`goods`+item.id"
            class="goods-item"
            @click="$router.push(`/m/detail/${item.id}`)"
          >
            <van-image
              fit="cover"
              height="15.6rem"
              :src="item.cover_img+'?x-oss-process=image/resize,w_500,limit_0'"
            />
            <span
              class="goods-item__name line-1"
              flex="main:center cross:center"
            >{{ item.name }}</span>
            <span
              class="goods-item__desc line-1"
              flex="main:center cross:center"
            >&nbsp;{{ item.desc }}</span>
            <span
              class="goods-item__price"
              flex="main:center cross:center"
            > &yen;{{ item.sale_price }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CateView',
  data() {
    return {
      goodsList: [],
      cid: 0,
      loading: true
    }
  },
  computed: {

  },
  async mounted() {
    this.cid = this.$route.params.id
    await this.getGoods()
  },
  methods: {
    async getGoods() {
      this.loading = true
      await this.$api.mch.goods({ category_id: this.cid }).then(res => {
        this.loading = false
        this.hots = res.data.hots
        this.goodsList = res.data.goods_list
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/variable.scss";
.wrapper{
  background: #fff;
  .dd-trade{
    width: 100vw;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    height: 8rem;
    &__weidian{
      background-color: #6431DA;
      background-image: url("@/assets/weidian.png");
      background-position: center;
      background-size: cover;
    }
    &__app{
      background-color: #F878CD;
      background-image: url("@/assets/app.png");
      background-position: center;
      background-size: cover;
      margin-bottom: 0;
    }
  }
  .cate-container{
    margin-top: .4rem;
    background: #f5f5f5;
    .goods-container{
      .goods-item{
        width: calc((100% - .4rem) /2) ;
        margin-right: .4rem;
        margin-bottom: .4rem;
        height: 22.6rem;
        background: #fff;
        border-radius: 0 .15rem .15rem 0;
        overflow: hidden;
        &:nth-child(2n){
          margin-right: 0;
          border-radius: .15rem 0 0 .15rem;
        }

        &__name{
          font-weight: 700;
          font-size: 1.3rem;
          padding: .2rem .5rem;
        }

        &__desc{
          font-size: 1.2rem;
          color: #999;
          padding: 0 .5rem;
        }

        &__price{
          color: $main-color;
          padding: 0 .5rem;
          font-size: 1.2rem;
        }
      }
    }
  }
}
</style>
